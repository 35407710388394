import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-16 max-w-4xl">
      <div className="bg-white rounded-lg shadow-lg p-8">
        <h1 className="text-4xl font-bold mb-2">Privacy Policy</h1>
        <p className="text-gray-600 mb-8">
          Last updated: {new Date().toLocaleDateString()}
        </p>

        <div className="space-y-8">
          <section>
            <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
            <p className="text-gray-700">
              Welcome to Intelisenze Infotech. We respect your privacy and are committed to protecting your personal data. This privacy policy explains how we handle your personal information when you visit our website (intelisenze.in) or use our contact form.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">2. Information We Collect</h2>
            <p className="text-gray-700">
              When you use our contact form, we collect:
            </p>
            <ul className="list-disc ml-6 mt-2 text-gray-700">
              <li>Name</li>
              <li>Email address</li>
              <li>Message content</li>
              <li>Timestamp of submission</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">3. How We Use Your Information</h2>
            <p className="text-gray-700">We use your information to:</p>
            <ul className="list-disc ml-6 mt-2 text-gray-700">
              <li>Respond to your inquiries</li>
              <li>Provide customer support</li>
              <li>Send you relevant information about our services</li>
              <li>Improve our website and services</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">4. Contact Form Data</h2>
            <p className="text-gray-700">When using our contact form:</p>
            <ul className="list-disc pl-8 mb-4 text-gray-700">
              <li>We only collect the information you provide (name, email, message)</li>
              <li>Your data is securely transmitted and processed</li>
              <li>We use this information solely to respond to your inquiries</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">5. Data Security</h2>
            <p className="text-gray-700">
              We implement appropriate security measures to protect your personal information. Your data is transmitted securely using SSL encryption, and we regularly review our security practices.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">6. Your Rights</h2>
            <p className="text-gray-700">You have the right to:</p>
            <ul className="list-disc ml-6 mt-2 text-gray-700">
              <li>Access your personal data</li>
              <li>Correct inaccurate data</li>
              <li>Request deletion of your data</li>
              <li>Withdraw consent at any time</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">7. Contact Us</h2>
            <p className="text-gray-700">
              For any questions about this privacy policy or our data practices, please contact us:
              <br />
              Email: info@intelisenze.in
              <br />
              Address: Goregaon(W), Mumbai - 400104, MH, India
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
