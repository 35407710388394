import React from 'react';
import { motion } from 'framer-motion';
import { IconType } from 'react-icons';
import {
  FaCode,
  FaMobile,
  FaCloud,
  FaDatabase,
  FaLaptopCode,
  FaCogs,
  FaDesktop,
  FaCheck,
  FaArrowDown,
  FaArrowRight,
  FaBolt,
  FaChartLine,
  FaSpinner,
  FaPaperPlane
} from 'react-icons/fa';
import { useContactForm } from '../hooks/useContactForm';
import { toast } from 'react-hot-toast';

interface FloatingIconProps {
  icon: any;
  delay?: number;
  index?: number;
  total?: number;
}

const ParticleBackground: React.FC = () => {
  const neonColors = [
    'rgba(80, 200, 255, 0.3)',  // Neon blue
    'rgba(255, 80, 255, 0.3)',  // Neon pink
    'rgba(80, 255, 140, 0.3)',  // Neon green
    'rgba(255, 180, 80, 0.3)',  // Neon orange
  ];

  return (
    <div className="absolute inset-0 overflow-hidden">
      {[...Array(50)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute w-1 h-1 rounded-full"
          style={{
            left: Math.random() * 100 + '%',
            top: Math.random() * 100 + '%',
            background: neonColors[Math.floor(Math.random() * neonColors.length)],
            boxShadow: '0 0 10px currentColor',
          }}
          animate={{
            scale: [1, 1.5, 1],
            opacity: [0.3, 0.6, 0.3],
            x: [0, Math.random() * 100 - 50],
            y: [0, Math.random() * 100 - 50],
          }}
          transition={{
            duration: Math.random() * 2 + 2,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        />
      ))}
    </div>
  );
};

const FloatingIcon: React.FC<FloatingIconProps> = ({ icon: Icon, delay = 0, index = 0, total = 1 }) => {
  const neonColors = [
    'text-[#50c8ff]',  // Neon blue
    'text-[#ff50ff]',  // Neon pink
    'text-[#50ff8c]',  // Neon green
    'text-[#ffb450]',  // Neon orange
  ];

  const randomNeonClass = neonColors[Math.floor(Math.random() * neonColors.length)];
  
  // Calculate initial position based on index for better distribution
  // Using golden ratio for more natural distribution
  const phi = (1 + Math.sqrt(5)) / 2;
  const angle = index * phi * 2 * Math.PI;
  const radius = 40; // Radius of distribution circle (in percentage)
  
  // Convert polar coordinates to cartesian
  const initialX = 50 + radius * Math.cos(angle);
  const initialY = 50 + radius * Math.sin(angle);

  return (
    <motion.div
      className="absolute"
      style={{
        left: `${initialX}%`,
        top: `${initialY}%`
      }}
      initial={{ opacity: 0, scale: 0 }}
      animate={{ 
        opacity: [0.4, 0.8, 0.4],
        scale: [1, 1.2, 1],
        x: [
          0,
          Math.random() * 300 - 150,
          Math.random() * 300 - 150,
          Math.random() * 300 - 150,
          0
        ],
        y: [
          0,
          Math.random() * 200 - 100,
          Math.random() * 200 - 100,
          Math.random() * 200 - 100,
          0
        ]
      }}
      transition={{
        duration: 25,
        delay: delay,
        repeat: Infinity,
        repeatType: "reverse",
        ease: "linear"
      }}
    >
      <div className="relative">
        <Icon className={`${randomNeonClass} text-xl filter drop-shadow-[0_0_8px_currentColor]`} />
        <div 
          className="absolute inset-0 blur-sm"
          style={{ 
            background: 'currentColor',
            opacity: 0.2,
          }} 
        />
      </div>
    </motion.div>
  );
};

const ConnectingLines: React.FC = () => {
  return (
    <svg className="absolute inset-0 w-full h-full">
      {[...Array(5)].map((_, i) => (
        <motion.path
          key={i}
          d={`M${Math.random() * 100},${Math.random() * 100} Q${Math.random() * 100},${Math.random() * 100} ${Math.random() * 100},${Math.random() * 100}`}
          stroke="rgba(80, 200, 255, 0.2)"
          strokeWidth="2"
          fill="none"
          filter="url(#neon-glow)"
          initial={{ pathLength: 0, opacity: 0 }}
          animate={{ 
            pathLength: [0, 1, 0],
            opacity: [0, 0.6, 0]
          }}
          transition={{ 
            duration: 3,
            delay: i * 0.5,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />
      ))}
      <defs>
        <filter id="neon-glow">
          <feGaussianBlur stdDeviation="2" result="blur" />
          <feComposite in="SourceGraphic" in2="blur" operator="over" />
        </filter>
      </defs>
    </svg>
  );
};

// Service Card Animation Component
interface ServiceCardPreviewProps {
  icon: JSX.Element;
  title: string;
  delay: number;
  sectionId: string;
}

const ServiceCardPreview: React.FC<ServiceCardPreviewProps> = ({ icon, title, delay, sectionId }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay }}
      className="flex flex-col items-center p-2 sm:p-4 bg-white/5 rounded-lg cursor-pointer hover:bg-white/10 transition-all"
      onClick={() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }}
    >
      <div className="text-2xl sm:text-3xl mb-2 sm:mb-4">
        {icon}
      </div>
      <span className="text-xs sm:text-sm text-white font-medium text-center">{title}</span>
    </motion.div>
  );
};

const Services: React.FC = () => {
  const {
    formData,
    formErrors,
    formStatus,
    handleChange,
    handleSubmit
  } = useContactForm();
  const toastContainerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (formStatus === 'success') {
      toast.success('Thank you! We will get back to you soon.');
    }
  }, [formStatus]);

  React.useEffect(() => {
    // Get the hash from the URL
    const hash = window.location.hash;
    if (hash) {
      // Remove the # from the hash
      const sectionId = hash.slice(1);
      // Find the element with the ID
      const element = document.getElementById(sectionId);
      if (element) {
        // Scroll to the element with smooth behavior
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, []);

  return (
    <div className="min-h-screen bg-primary text-white">
      {/* Hero Section */}
      <div className="relative h-[60vh] sm:h-[42vh] bg-primary overflow-hidden">
        {/* Animated Background */}
        <div className="absolute inset-0">
          {/* Grid Pattern */}
          <div className="absolute inset-0" style={{ 
            backgroundImage: 'linear-gradient(to right, rgba(80, 200, 255, 0.15) 1px, transparent 1px), linear-gradient(to bottom, rgba(80, 200, 255, 0.15) 1px, transparent 1px)',
            backgroundSize: '20px 20px'
          }} />
          {/* Particles - Hide on mobile */}
          <div className="hidden sm:block">
            <ParticleBackground />
          </div>

          {/* Floating Icons - Reduce for mobile */}
          <div className="hidden sm:block">
            {[FaCode, FaLaptopCode, FaDatabase, FaMobile, FaCloud, FaCogs].map((icon, i) => (
              <FloatingIcon 
                key={i} 
                icon={icon} 
                delay={i * 0.2} 
                index={i}
                total={6}
              />
            ))}
          </div>
          <div className="sm:hidden">
            {[FaCode, FaLaptopCode, FaDatabase].map((icon, i) => (
              <FloatingIcon 
                key={i} 
                icon={icon} 
                delay={i * 0.2} 
                index={i}
                total={3}
              />
            ))}
          </div>

          {/* Connecting Lines - Hide on mobile */}
          <div className="hidden sm:block">
            <ConnectingLines />
          </div>
        </div>

        {/* Hero Content */}
        <div className="relative z-10 h-full flex flex-col justify-center items-center px-4">
          <div className="max-w-6xl mx-auto w-full text-center">
            {/* Main Title */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="mb-4 sm:mb-8"
            >
              <h1 className="text-2xl sm:text-3xl md:text-5xl font-bold text-white mb-2 sm:mb-4 text-center">
                Our Expertise in Action
              </h1>
              <p className="text-lg sm:text-xl text-blue-200 font-light text-center">
                End-to-End Digital Solutions
              </p>
            </motion.div>

            {/* Service Preview Cards - Adjust for mobile */}
            <div className="grid grid-cols-2 sm:flex sm:flex-wrap justify-center gap-3 sm:gap-4">
              <div className="col-span-1">
                <ServiceCardPreview 
                  icon={<FaLaptopCode className="w-5 h-5 sm:w-6 sm:h-6" />} 
                  title="Website Development" 
                  delay={0.3}
                  sectionId="web-development"
                />
              </div>
              <div className="col-span-1">
                <ServiceCardPreview 
                  icon={<FaCode className="w-5 h-5 sm:w-6 sm:h-6" />} 
                  title="Software Development" 
                  delay={0.4}
                  sectionId="software-development"
                />
              </div>
              <div className="col-span-1">
                <ServiceCardPreview 
                  icon={<FaDesktop className="w-5 h-5 sm:w-6 sm:h-6" />} 
                  title="Web Applications" 
                  delay={0.5}
                  sectionId="web-app-development"
                />
              </div>
              <div className="col-span-1">
                <ServiceCardPreview 
                  icon={<FaMobile className="w-5 h-5 sm:w-6 sm:h-6" />} 
                  title="Mobile Development" 
                  delay={0.6}
                  sectionId="mobile-development"
                />
              </div>
            </div>

            {/* Stats Section */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.8 }}
              className="flex justify-center gap-4 sm:gap-8 text-white"
            >
              <div className="text-center">
                <div className="text-2xl sm:text-3xl font-bold text-accent">95%</div>
                <div className="text-xs sm:text-sm text-blue-200">Client Satisfaction</div>
              </div>
              <div className="text-center">
                <div className="text-2xl sm:text-3xl font-bold text-accent">10+</div>
                <div className="text-xs sm:text-sm text-blue-200">Years Experience</div>
              </div>
              <div className="text-center">
                <div className="text-2xl sm:text-3xl font-bold text-accent">5+</div>
                <div className="text-xs sm:text-sm text-blue-200">Industries Served</div>
              </div>
            </motion.div>

            {/* CTA Button */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6, delay: 1 }}
              className="mt-8 flex justify-center"
            >
              <a 
                href="#explore-services" 
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById('explore-services')?.scrollIntoView({ 
                    behavior: 'smooth',
                    block: 'start'
                  });
                }}
                className="inline-flex items-center gap-2 bg-accent text-white px-6 py-3 rounded-full font-medium hover:bg-accent/90 transition-colors"
              >
                Explore Our Services
                <FaArrowDown className="text-sm" />
              </a>
            </motion.div>
          </div>
        </div>

        {/* Bottom Curve */}
        <div className="absolute bottom-0 left-0 right-0">
          <svg 
            viewBox="0 0 1440 50" 
            xmlns="http://www.w3.org/2000/svg"
            className="fill-light"
          >
            <path 
              d="M0,32L48,29.3C96,27,192,21,288,21.3C384,21,480,27,576,29.3C672,32,768,32,864,29.3C960,27,1056,21,1152,21.3C1248,21,1344,27,1392,29.3L1440,32L1440,50L1392,50C1344,50,1248,50,1152,50C1056,50,960,50,864,50C768,50,672,50,576,50C480,50,384,50,288,50C192,50,96,50,48,50L0,50Z"
            />
          </svg>
        </div>
      </div>

      {/* Services Section */}
      <div id="explore-services" className="bg-light py-16">
        <div className="container mx-auto px-4 max-w-7xl">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center mb-12"
          >
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Our Core Services
            </h2>
            <p className="text-lg text-gray-600 max-w-3xl mx-auto">
              Transforming ideas into reality with cutting-edge technology solutions
            </p>
          </motion.div>

          {/* Service Cards */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {/* Web Development */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              id="web-development"
              className="group relative overflow-hidden rounded-2xl bg-gradient-to-br from-white/5 to-white/10 backdrop-blur-sm border border-white/10 p-8 hover:shadow-lg transition-all duration-300"
            >
              <div className="absolute top-0 right-0 w-32 h-32 bg-blue-500/10 rounded-full blur-3xl group-hover:bg-blue-500/20 transition-all duration-300"></div>
              <div className="relative z-10">
                <div className="flex items-center gap-4 mb-6">
                  <div className="p-3 bg-blue-500/10 rounded-xl">
                    <FaLaptopCode className="text-3xl text-blue-500" />
                  </div>
                  <h3 className="text-2xl font-bold text-gray-900">Web Development</h3>
                </div>
                <p className="text-gray-600 mb-6">
                  Creating stunning, responsive websites that captivate your audience and drive results. We combine cutting-edge technology with exceptional user experience.
                </p>
                <div className="space-y-4">
                  <h4 className="font-semibold text-gray-800 mb-3">Key Features:</h4>
                  <ul className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <li className="flex items-center gap-2 text-gray-600">
                      <div className="p-1 bg-green-500/10 rounded-full">
                        <FaCheck className="text-sm text-green-500" />
                      </div>
                      Responsive Design
                    </li>
                    <li className="flex items-center gap-2 text-gray-600">
                      <div className="p-1 bg-green-500/10 rounded-full">
                        <FaCheck className="text-sm text-green-500" />
                      </div>
                      SEO Optimization
                    </li>
                    <li className="flex items-center gap-2 text-gray-600">
                      <div className="p-1 bg-green-500/10 rounded-full">
                        <FaCheck className="text-sm text-green-500" />
                      </div>
                      Custom Animations
                    </li>
                    <li className="flex items-center gap-2 text-gray-600">
                      <div className="p-1 bg-green-500/10 rounded-full">
                        <FaCheck className="text-sm text-green-500" />
                      </div>
                      Performance Optimization
                    </li>
                  </ul>
                </div>
                <div className="mt-8 flex items-center gap-2 text-blue-500 group/link">
                  <span className="font-medium">Learn More</span>
                  <FaArrowRight className="text-sm group-hover/link:translate-x-1 transition-transform" />
                </div>
              </div>
            </motion.div>

            {/* Software Development */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              id="software-development"
              className="group relative overflow-hidden rounded-2xl bg-gradient-to-br from-white/5 to-white/10 backdrop-blur-sm border border-white/10 p-8 hover:shadow-lg transition-all duration-300"
            >
              <div className="absolute top-0 right-0 w-32 h-32 bg-purple-500/10 rounded-full blur-3xl group-hover:bg-purple-500/20 transition-all duration-300"></div>
              <div className="relative z-10">
                <div className="flex items-center gap-4 mb-6">
                  <div className="p-3 bg-purple-500/10 rounded-xl">
                    <FaCode className="text-3xl text-purple-500" />
                  </div>
                  <h3 className="text-2xl font-bold text-gray-900">Software Development</h3>
                </div>
                <p className="text-gray-600 mb-6">
                  Building robust, scalable software solutions that power your business. Our expert team delivers custom applications tailored to your needs.
                </p>
                <div className="space-y-4">
                  <h4 className="font-semibold text-gray-800 mb-3">Key Features:</h4>
                  <ul className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <li className="flex items-center gap-2 text-gray-600">
                      <div className="p-1 bg-green-500/10 rounded-full">
                        <FaCheck className="text-sm text-green-500" />
                      </div>
                      Custom Solutions
                    </li>
                    <li className="flex items-center gap-2 text-gray-600">
                      <div className="p-1 bg-green-500/10 rounded-full">
                        <FaCheck className="text-sm text-green-500" />
                      </div>
                      Cloud Integration
                    </li>
                    <li className="flex items-center gap-2 text-gray-600">
                      <div className="p-1 bg-green-500/10 rounded-full">
                        <FaCheck className="text-sm text-green-500" />
                      </div>
                      API Development
                    </li>
                    <li className="flex items-center gap-2 text-gray-600">
                      <div className="p-1 bg-green-500/10 rounded-full">
                        <FaCheck className="text-sm text-green-500" />
                      </div>
                      Database Design
                    </li>
                  </ul>
                </div>
                <div className="mt-8 flex items-center gap-2 text-purple-500 group/link">
                  <span className="font-medium">Learn More</span>
                  <FaArrowRight className="text-sm group-hover/link:translate-x-1 transition-transform" />
                </div>
              </div>
            </motion.div>

            {/* Web App Development */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              id="web-app-development"
              className="group relative overflow-hidden rounded-2xl bg-gradient-to-br from-white/5 to-white/10 backdrop-blur-sm border border-white/10 p-8 hover:shadow-lg transition-all duration-300"
            >
              <div className="absolute top-0 right-0 w-32 h-32 bg-teal-500/10 rounded-full blur-3xl group-hover:bg-teal-500/20 transition-all duration-300"></div>
              <div className="relative z-10">
                <div className="flex items-center gap-4 mb-6">
                  <div className="p-3 bg-teal-500/10 rounded-xl">
                    <FaDesktop className="text-3xl text-teal-500" />
                  </div>
                  <h3 className="text-2xl font-bold text-gray-900">Web Applications</h3>
                </div>
                <p className="text-gray-600 mb-6">
                  Developing powerful web applications that streamline your operations. We create intuitive, feature-rich solutions for modern businesses.
                </p>
                <div className="space-y-4">
                  <h4 className="font-semibold text-gray-800 mb-3">Key Features:</h4>
                  <ul className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <li className="flex items-center gap-2 text-gray-600">
                      <div className="p-1 bg-green-500/10 rounded-full">
                        <FaCheck className="text-sm text-green-500" />
                      </div>
                      Real-time Updates
                    </li>
                    <li className="flex items-center gap-2 text-gray-600">
                      <div className="p-1 bg-green-500/10 rounded-full">
                        <FaCheck className="text-sm text-green-500" />
                      </div>
                      User Authentication
                    </li>
                    <li className="flex items-center gap-2 text-gray-600">
                      <div className="p-1 bg-green-500/10 rounded-full">
                        <FaCheck className="text-sm text-green-500" />
                      </div>
                      Data Visualization
                    </li>
                    <li className="flex items-center gap-2 text-gray-600">
                      <div className="p-1 bg-green-500/10 rounded-full">
                        <FaCheck className="text-sm text-green-500" />
                      </div>
                      Offline Support
                    </li>
                  </ul>
                </div>
                <div className="mt-8 flex items-center gap-2 text-teal-500 group/link">
                  <span className="font-medium">Learn More</span>
                  <FaArrowRight className="text-sm group-hover/link:translate-x-1 transition-transform" />
                </div>
              </div>
            </motion.div>

            {/* Mobile Development */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              id="mobile-development"
              className="group relative overflow-hidden rounded-2xl bg-gradient-to-br from-white/5 to-white/10 backdrop-blur-sm border border-white/10 p-8 hover:shadow-lg transition-all duration-300"
            >
              <div className="absolute top-0 right-0 w-32 h-32 bg-orange-500/10 rounded-full blur-3xl group-hover:bg-orange-500/20 transition-all duration-300"></div>
              <div className="relative z-10">
                <div className="flex items-center gap-4 mb-6">
                  <div className="p-3 bg-orange-500/10 rounded-xl">
                    <FaMobile className="text-3xl text-orange-500" />
                  </div>
                  <h3 className="text-2xl font-bold text-gray-900">Mobile Development</h3>
                </div>
                <p className="text-gray-600 mb-6">
                  Creating engaging mobile applications that connect with your users. We deliver native and cross-platform solutions that perform.
                </p>
                <div className="space-y-4">
                  <h4 className="font-semibold text-gray-800 mb-3">Key Features:</h4>
                  <ul className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <li className="flex items-center gap-2 text-gray-600">
                      <div className="p-1 bg-green-500/10 rounded-full">
                        <FaCheck className="text-sm text-green-500" />
                      </div>
                      Native Performance
                    </li>
                    <li className="flex items-center gap-2 text-gray-600">
                      <div className="p-1 bg-green-500/10 rounded-full">
                        <FaCheck className="text-sm text-green-500" />
                      </div>
                      Cross-platform
                    </li>
                    <li className="flex items-center gap-2 text-gray-600">
                      <div className="p-1 bg-green-500/10 rounded-full">
                        <FaCheck className="text-sm text-green-500" />
                      </div>
                      Push Notifications
                    </li>
                    <li className="flex items-center gap-2 text-gray-600">
                      <div className="p-1 bg-green-500/10 rounded-full">
                        <FaCheck className="text-sm text-green-500" />
                      </div>
                      App Store Support
                    </li>
                  </ul>
                </div>
                <div className="mt-8 flex items-center gap-2 text-orange-500 group/link">
                  <span className="font-medium">Learn More</span>
                  <FaArrowRight className="text-sm group-hover/link:translate-x-1 transition-transform" />
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Quick Contact Section */}
      <div className="pt-0 pb-8 sm:pb-12 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="max-w-6xl mx-auto bg-white rounded-xl sm:rounded-2xl shadow-lg overflow-hidden">
            {/* Header */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="flex flex-col sm:flex-row items-start sm:items-center justify-between p-4 sm:p-6 md:p-8 border-b border-gray-100"
            >
              <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-primary mb-3 sm:mb-0">
                Let's Discuss Your Project
              </h2>
              <div className="flex flex-wrap gap-3 sm:gap-6">
                <div className="flex items-center gap-1.5 sm:gap-2 text-gray-600 text-xs sm:text-sm">
                  <FaBolt className="text-accent" />
                  <span>Quick Response</span>
                </div>
                <div className="flex items-center gap-1.5 sm:gap-2 text-gray-600 text-xs sm:text-sm">
                  <FaChartLine className="text-accent" />
                  <span>Free Consultation</span>
                </div>
              </div>
            </motion.div>

            {/* Contact Form */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="p-4 sm:p-6 md:p-8"
            >
              <form 
                onSubmit={handleSubmit} 
                className="flex flex-col md:flex-row gap-3 sm:gap-4 items-stretch"
              >
                <div className="w-full md:w-1/4 flex flex-col gap-3 sm:gap-4">
                  <div>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Your Name"
                      className={`w-full h-10 sm:h-[46px] px-3 sm:px-4 py-2 sm:py-3 rounded-lg bg-gray-50 border ${formErrors.name ? 'border-red-500' : 'border-gray-200'} text-gray-800 placeholder-gray-500 focus:outline-none focus:border-accent focus:ring-1 focus:ring-accent/20 transition-colors text-sm`}
                      disabled={formStatus === 'submitting'}
                    />
                    {formErrors.name && (
                      <p className="mt-1 text-xs text-red-500">{formErrors.name}</p>
                    )}
                  </div>
                  <div>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Your Email"
                      className={`w-full h-10 sm:h-[46px] px-3 sm:px-4 py-2 sm:py-3 rounded-lg bg-gray-50 border ${formErrors.email ? 'border-red-500' : 'border-gray-200'} text-gray-800 placeholder-gray-500 focus:outline-none focus:border-accent focus:ring-1 focus:ring-accent/20 transition-colors text-sm`}
                      disabled={formStatus === 'submitting'}
                    />
                    {formErrors.email && (
                      <p className="mt-1 text-xs text-red-500">{formErrors.email}</p>
                    )}
                  </div>
                </div>

                <div className="w-full md:w-1/2 flex flex-col gap-3 sm:gap-4">
                  <div>
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      placeholder="Tell us about your project"
                      className={`w-full h-[108px] px-3 sm:px-4 py-2 sm:py-3 rounded-lg bg-gray-50 border ${formErrors.message ? 'border-red-500' : 'border-gray-200'} text-gray-800 placeholder-gray-500 focus:outline-none focus:border-accent focus:ring-1 focus:ring-accent/20 transition-colors text-sm resize-none`}
                      disabled={formStatus === 'submitting'}
                    />
                    {formErrors.message && (
                      <p className="mt-1 text-xs text-red-500">{formErrors.message}</p>
                    )}
                  </div>
                </div>

                <div className="w-full md:w-1/4 flex items-center relative">
                  <button
                    type="submit"
                    disabled={formStatus === 'submitting'}
                    className={`w-full h-10 sm:h-[46px] px-6 rounded-lg bg-accent text-white font-medium hover:bg-accent/90 focus:outline-none focus:ring-2 focus:ring-accent/20 disabled:opacity-50 disabled:cursor-not-allowed transition-colors flex items-center justify-center gap-2 ${
                      formStatus === 'submitting' ? 'cursor-not-allowed' : ''
                    }`}
                  >
                    {formStatus === 'submitting' ? (
                      <>
                        <FaSpinner className="animate-spin" />
                        <span>Sending...</span>
                      </>
                    ) : (
                      <>
                        <span>Send Message</span>
                        <FaPaperPlane className="text-sm" />
                      </>
                    )}
                  </button>
                  <div 
                    ref={toastContainerRef}
                    className="absolute left-0 right-0 -bottom-16"
                  />
                </div>
              </form>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
