import { useState } from 'react';
import { sendContactForm } from '../services/emailService';
import { toast } from 'react-toastify';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

interface FormData {
  name: string;
  email: string;
  message: string;
}

interface ValidationErrors {
  name?: string;
  email?: string;
  message?: string;
}

export const useContactForm = () => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    message: ''
  });
  const [formErrors, setFormErrors] = useState<ValidationErrors>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formStatus, setFormStatus] = useState<'idle' | 'submitting' | 'success' | 'error'>('idle');
  const { executeRecaptcha } = useGoogleReCaptcha();

  const validateForm = (): ValidationErrors => {
    const errors: ValidationErrors = {};
    
    if (!formData.name.trim() || formData.name.length < 2) {
      errors.name = 'Name must be at least 2 characters long';
    }
    
    if (!formData.email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = 'Please enter a valid email address';
    }
    
    if (!formData.message.trim() || formData.message.length < 10) {
      errors.message = 'Message must be at least 10 characters long';
    }

    if (formData.name.length > 100) {
      errors.name = 'Name must not exceed 100 characters';
    }

    if (formData.message.length > 5000) {
      errors.message = 'Message must not exceed 5000 characters';
    }

    setFormErrors(errors);
    return errors;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      Object.values(errors).forEach(error => {
        if (error) toast.error(error);
      });
      return;
    }

    if (!executeRecaptcha) {
      toast.error('reCAPTCHA not ready');
      return;
    }

    let recaptchaToken;
    try {
      recaptchaToken = await executeRecaptcha('contact_form');
    } catch (error) {
      console.error('reCAPTCHA error:', error);
      toast.error('Failed to verify reCAPTCHA. Please try again.');
      return;
    }

    try {
      setIsSubmitting(true);
      setFormStatus('submitting');
      
      await sendContactForm({
        ...formData,
        recaptchaToken
      });
      
      toast.success('Message sent successfully!');
      setFormData({ name: '', email: '', message: '' });
      setFormStatus('success');
    } catch (error) {
      console.error('Error sending message:', error);
      if (error instanceof Error) {
        toast.error(error.message || 'Failed to send message');
      } else {
        toast.error('An unexpected error occurred');
      }
      setFormStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    if (formErrors[name as keyof ValidationErrors]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: undefined
      }));
    }
  };

  return {
    formData,
    formErrors,
    formStatus,
    isSubmitting,
    handleChange,
    handleSubmit
  };
};
