import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Scroll to top when location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleNavigation = (path: string) => {
    setIsOpen(false);
    navigate(path);
  };

  const navItems = [
    { name: 'Home', path: '/' },
    { name: 'Services', path: '/services' },
    { name: 'Projects', path: '/projects' },
    { name: 'About', path: '/about' },
    { name: 'Contact', path: '/contact' }
  ];

  return (
    <nav className="bg-white shadow-md fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden">
      <div className="container mx-auto px-4 py-2.5 flex justify-between items-center">
        {/* Logo */}
        <div 
          onClick={() => handleNavigation('/')}
          className="flex items-center cursor-pointer"
        >
          <img
            src="/assets/images/logo@2x.jpg"
            alt="Intelisenze Logo"
            className="h-14 w-auto"
            style={{
              objectFit: 'contain',
              maxWidth: '160px'
            }}
          />
        </div>

        {/* Mobile Menu Toggle */}
        <button 
          onClick={toggleMenu} 
          className="inline-flex items-center justify-center p-2 rounded-md text-gray-800 hover:bg-gray-100 focus:outline-none transition-colors md:hidden"
          aria-label={isOpen ? "Close menu" : "Open menu"}
        >
          {isOpen ? <FaTimes className="h-6 w-6" /> : <FaBars className="h-6 w-6" />}
        </button>

        {/* Desktop Navigation */}
        <div className="hidden md:block">
          <ul className="flex space-x-8 items-center">
            {navItems.map((item) => (
              <li key={item.path}>
                <div 
                  onClick={() => handleNavigation(item.path)}
                  className={`relative text-lg font-medium group py-2 cursor-pointer ${
                    location.pathname === item.path
                      ? 'text-primary'
                      : 'text-dark hover:text-primary'
                  }`}
                >
                  {item.name}
                  <span 
                    className={`absolute bottom-0 left-0 w-full h-0.5 transform origin-left transition-transform duration-300 ease-out ${
                      location.pathname === item.path ? 'scale-x-100' : 'scale-x-0 group-hover:scale-x-100'
                    }`}
                    style={{
                      background: 'linear-gradient(90deg, #3B82F6 0%, #2563EB 50%, #1E40AF 100%)',
                      opacity: '0.85'
                    }}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* Mobile Navigation Dropdown */}
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.2 }}
              className="md:hidden fixed top-[72px] left-0 right-0 bg-white shadow-lg border-t border-gray-100 max-h-[calc(100vh-72px)] overflow-y-auto"
              style={{ zIndex: 40 }}
            >
              <ul className="flex flex-col py-2">
                {navItems.map((item, index) => (
                  <motion.li
                    key={item.path}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.2, delay: index * 0.05 }}
                  >
                    <div
                      onClick={() => handleNavigation(item.path)}
                      className={`block px-6 py-3 text-base font-medium transition-all duration-200 relative cursor-pointer ${
                        location.pathname === item.path
                          ? 'text-primary bg-gradient-to-r from-blue-50 to-transparent'
                          : 'text-dark hover:text-primary hover:bg-gray-50'
                      }`}
                    >
                      {item.name}
                      {location.pathname === item.path && (
                        <span 
                          className="absolute left-0 top-0 h-full w-1"
                          style={{
                            background: 'linear-gradient(to bottom, #3B82F6, #1E40AF)'
                          }}
                        />
                      )}
                    </div>
                  </motion.li>
                ))}
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </nav>
  );
};

export default Navbar;
