import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { IconType } from 'react-icons';
import { 
  FaCode,
  FaMobile,
  FaCloud,
  FaDatabase,
  FaLaptopCode,
  FaCogs,
  FaRobot,
  FaTools,
  FaUserCog,
  FaServer,
  FaArrowRight,
  FaBolt,
  FaChartLine,
  FaSpinner,
  FaPaperPlane
} from 'react-icons/fa';
import { useContactForm } from '../hooks/useContactForm';
import AOS from 'aos';

interface ServiceFeature {
  text: string;
  icon: IconType;
}

interface ServiceItem {
  icon: JSX.Element;
  title: string;
  description: string;
  features: ServiceFeature[];
  color: string;
}

interface FloatingIconProps {
  icon: IconType;
  delay?: number;
  index?: number;
  total?: number;
}

const FloatingIcon: React.FC<FloatingIconProps> = ({ icon: Icon, delay = 0, index = 0, total = 1 }) => {
  const neonColors = [
    'text-[#50c8ff]',  // Neon blue
    'text-[#ff50ff]',  // Neon pink
    'text-[#50ff8c]',  // Neon green
    'text-[#ffb450]',  // Neon orange
  ];

  const randomNeonClass = neonColors[Math.floor(Math.random() * neonColors.length)];
  
  const phi = (1 + Math.sqrt(5)) / 2;
  const angle = index * phi * 2 * Math.PI;
  const radius = 40;
  
  const initialX = 50 + radius * Math.cos(angle);
  const initialY = 50 + radius * Math.sin(angle);

  return (
    <motion.div
      className="absolute"
      style={{
        left: `${initialX}%`,
        top: `${initialY}%`
      }}
      initial={{ opacity: 0, scale: 0 }}
      animate={{ 
        opacity: [0.4, 0.8, 0.4],
        scale: [1, 1.2, 1],
        x: [
          0,
          Math.random() * 300 - 150,
          Math.random() * 300 - 150,
          Math.random() * 300 - 150,
          0
        ],
        y: [
          0,
          Math.random() * 200 - 100,
          Math.random() * 200 - 100,
          Math.random() * 200 - 100,
          0
        ]
      }}
      transition={{
        duration: 25,
        delay: delay,
        repeat: Infinity,
        repeatType: "reverse",
        ease: "linear"
      }}
    >
      <div className="relative">
        <Icon className={`${randomNeonClass} text-xl sm:text-2xl filter drop-shadow-[0_0_8px_currentColor]`} />
        <div 
          className="absolute inset-0 blur-sm"
          style={{ 
            background: 'currentColor',
            opacity: 0.2,
          }} 
        />
      </div>
    </motion.div>
  );
};

const ParticleBackground: React.FC = () => {
  const neonColors = [
    'rgba(80, 200, 255, 0.3)',  // Neon blue
    'rgba(255, 80, 255, 0.3)',  // Neon pink
    'rgba(80, 255, 140, 0.3)',  // Neon green
    'rgba(255, 180, 80, 0.3)',  // Neon orange
  ];

  return (
    <div className="absolute inset-0 overflow-hidden">
      {[...Array(50)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute w-1 h-1 rounded-full"
          style={{
            left: Math.random() * 100 + '%',
            top: Math.random() * 100 + '%',
            background: neonColors[Math.floor(Math.random() * neonColors.length)],
            boxShadow: '0 0 10px currentColor',
          }}
          animate={{
            scale: [1, 1.5, 1],
            opacity: [0.3, 0.6, 0.3],
            x: [0, Math.random() * 100 - 50],
            y: [0, Math.random() * 100 - 50],
          }}
          transition={{
            duration: Math.random() * 2 + 2,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        />
      ))}
    </div>
  );
};

const ConnectingLines: React.FC = () => {
  return (
    <div className="absolute inset-0">
      <svg className="w-full h-full">
        <defs>
          <linearGradient id="lineGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="rgba(80, 200, 255, 0.2)" />
            <stop offset="100%" stopColor="rgba(255, 80, 255, 0.2)" />
          </linearGradient>
        </defs>
        {[...Array(8)].map((_, i) => {
          const x1 = Math.random() * 100;
          const y1 = Math.random() * 100;
          const x2 = Math.random() * 100;
          const y2 = Math.random() * 100;
          return (
            <motion.line
              key={i}
              x1={`${x1}%`}
              y1={`${y1}%`}
              x2={`${x2}%`}
              y2={`${y2}%`}
              stroke="url(#lineGradient)"
              strokeWidth="1"
              initial={{ pathLength: 0, opacity: 0 }}
              animate={{ 
                pathLength: [0, 1, 1, 0],
                opacity: [0, 0.2, 0.2, 0]
              }}
              transition={{
                duration: 8,
                delay: i * 0.5,
                repeat: Infinity,
                ease: "linear"
              }}
            />
          );
        })}
      </svg>
    </div>
  );
};

const Home: React.FC = () => {
  const { formData, formErrors, handleChange, handleSubmit, formStatus } = useContactForm();
  const toastContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const services: ServiceItem[] = [
    {
      icon: <FaLaptopCode />,
      title: 'Web Development',
      description: 'Crafting responsive, modern websites that drive engagement and conversions.',
      features: [
        { text: 'Custom Web Apps', icon: FaCode },
        { text: 'Responsive Design', icon: FaMobile },
        { text: 'Performance Optimization', icon: FaCloud }
      ],
      color: 'from-blue-500 to-cyan-500'
    },
    {
      icon: <FaCode />,
      title: 'Software Development',
      description: 'Building robust, scalable software solutions tailored to your business needs.',
      features: [
        { text: 'Custom Solutions', icon: FaCogs },
        { text: 'API Integration', icon: FaServer },
        { text: 'Cloud Services', icon: FaDatabase }
      ],
      color: 'from-purple-500 to-pink-500'
    },
    {
      icon: <FaCloud />,
      title: 'Web App Development',
      description: 'Creating powerful, cloud-native web applications with cutting-edge technologies.',
      features: [
        { text: 'Cloud Native', icon: FaCloud },
        { text: 'Real-time Apps', icon: FaRobot },
        { text: 'Progressive Web Apps', icon: FaTools }
      ],
      color: 'from-green-500 to-teal-500'
    },
    {
      icon: <FaRobot />,
      title: 'Digital Transformation',
      description: 'Helping businesses leverage technology to innovate and stay competitive.',
      features: [
        { text: 'Process Automation', icon: FaUserCog },
        { text: 'Digital Strategy', icon: FaCode },
        { text: 'Tech Consulting', icon: FaServer }
      ],
      color: 'from-orange-500 to-red-500'
    }
  ];

  return (
    <div className="text-center">
      {/* Hero Section */}
      <div className="relative h-[60vh] sm:h-[42vh] bg-primary overflow-hidden">
        {/* Animated Background */}
        <div className="absolute inset-0">
          {/* Grid Pattern */}
          <div className="absolute inset-0" style={{ 
            backgroundImage: 'linear-gradient(to right, rgba(80, 200, 255, 0.15) 1px, transparent 1px), linear-gradient(to bottom, rgba(80, 200, 255, 0.15) 1px, transparent 1px)',
            backgroundSize: '30px 30px'
          }} />

          {/* Particles */}
          <div className="block opacity-50 sm:opacity-100">
            <ParticleBackground />
          </div>

          {/* Connecting Lines */}
          <ConnectingLines />

          {/* Floating Icons */}
          <div className="block">
            {[FaCode, FaMobile, FaCloud, FaDatabase, FaLaptopCode, FaCogs].map((icon, i) => (
              <FloatingIcon 
                key={i}
                icon={icon}
                delay={i * 0.2}
                index={i}
                total={6}
              />
            ))}
          </div>
        </div>

        {/* Hero Content */}
        <div className="relative z-10 h-full flex flex-col justify-center items-center px-4 py-8 sm:py-0">
          <div className="max-w-6xl mx-auto w-full">
            <h1 
              className="text-2xl sm:text-3xl md:text-5xl font-bold text-white mb-3 sm:mb-4 leading-tight px-2"
            >
              Transforming Ideas into
              <br className="hidden sm:block" />
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-accent to-tech-green">
                Digital Reality
              </span>
            </h1>
            
            <p 
              className="text-sm sm:text-base md:text-lg text-gray-300 mb-4 sm:mb-6 max-w-2xl mx-auto px-4"
            >
              We craft innovative digital solutions that empower businesses
              to thrive in the modern world.
            </p>

            <div 
              className="flex flex-col sm:flex-row gap-3 justify-center items-center mb-4 sm:mb-6 px-4"
            >
              <a 
                href="/contact"
                className="w-full sm:w-auto group flex items-center justify-center gap-2 bg-white text-primary px-4 py-2 sm:px-5 sm:py-2.5 rounded-full text-sm sm:text-base font-semibold hover:bg-opacity-90 transition-all"
              >
                Get Started
                <FaArrowRight className="group-hover:translate-x-1 transition-transform" />
              </a>
              <a 
                href="/projects"
                className="w-full sm:w-auto flex items-center justify-center gap-2 border-2 border-white text-white px-4 py-2 sm:px-5 sm:py-2.5 rounded-full text-sm sm:text-base font-semibold hover:bg-white/10 transition-all"
              >
                View Our Work
              </a>
            </div>

            {/* Tech Stack Icons */}
            <div 
              className="flex justify-center gap-3 sm:gap-4 mt-6 sm:mt-8 px-2"
            >
              <div 
                className="bg-white/10 backdrop-blur-lg p-2 sm:p-3 rounded-xl"
              >
                <FaCode className="text-2xl sm:text-3xl text-blue-400" />
              </div>
              <div 
                className="bg-white/10 backdrop-blur-lg p-2 sm:p-3 rounded-xl"
              >
                <FaCloud className="text-2xl sm:text-3xl text-green-400" />
              </div>
              <div 
                className="bg-white/10 backdrop-blur-lg p-2 sm:p-3 rounded-xl"
              >
                <FaDatabase className="text-2xl sm:text-3xl text-purple-400" />
              </div>
            </div>
          </div>
        </div>

        {/* Curved bottom */}
        <div className="absolute bottom-0 left-0 right-0">
          <svg 
            viewBox="0 0 1440 50" 
            xmlns="http://www.w3.org/2000/svg"
            className="fill-light w-full"
            preserveAspectRatio="none"
          >
            <path 
              d="M0,32L48,29.3C96,27,192,21,288,21.3C384,21,480,27,576,29.3C672,32,768,32,864,29.3C960,27,1056,21,1152,21.3C1248,21,1344,27,1392,29.3L1440,32L1440,50L1392,50C1344,50,1248,50,1152,50C1056,50,960,50,864,50C768,50,672,50,576,50C480,50,384,50,288,50C192,50,96,50,48,50L0,50Z"
            />
          </svg>
        </div>
      </div>

      {/* Services Section */}
      <div className="bg-light py-8 sm:py-12 lg:py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl sm:text-4xl font-bold mb-6 sm:mb-8">Core Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 lg:gap-8">
            {services.map((service, index) => (
              <div
                key={index}
                className={`relative p-4 sm:p-6 rounded-xl bg-white shadow-lg hover:shadow-xl transition-all duration-300 cursor-pointer overflow-hidden`}
              >
                <div className={`absolute top-0 left-0 w-full h-1 bg-gradient-to-r ${service.color}`} />
                <div className="flex justify-center items-center text-2xl sm:text-3xl text-gray-600 mb-3 sm:mb-4">
                  {service.icon}
                </div>
                <h3 className="text-lg sm:text-xl font-semibold mb-2 sm:mb-3">{service.title}</h3>
                <p className="text-sm sm:text-base text-gray-600 mb-3 sm:mb-4">{service.description}</p>
                <ul className="space-y-2 sm:space-y-3">
                  {service.features.map((feature, featureIndex) => {
                    const Icon = feature.icon;
                    return (
                      <li
                        key={featureIndex}
                        className="flex items-center justify-center space-x-2 text-gray-700"
                      >
                        <Icon className="text-base sm:text-lg text-gray-500" />
                        <span className="text-sm sm:text-base">{feature.text}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* How It Works Section */}
      <div className="pt-8 pb-12 bg-gradient-to-b from-white to-gray-50">
        <div className="container mx-auto px-4">
          <div className="max-w-6xl mx-auto text-center">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-primary mb-3 sm:mb-4">
              How It Works
            </h2>
            <p className="text-gray-600 text-base sm:text-lg md:text-xl px-4 sm:px-0">
              Our proven development process ensures high-quality solutions delivered on time
            </p>
          </div>

          {/* Development Lifecycle Visualization */}
          <div className="relative max-w-5xl mx-auto">
            {/* Central connecting circle */}
            <div
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-32 sm:w-48 h-32 sm:h-48 rounded-full border-2 border-blue-200"
            />

            {/* Development stages */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 sm:gap-6 md:gap-8">
              {/* Planning Stage */}
              <div
                className="relative"
              >
                <div className="bg-white p-4 sm:p-6 rounded-xl shadow-lg hover:shadow-xl transition-all">
                  <div className="w-12 h-12 sm:w-16 sm:h-16 bg-gradient-to-br from-blue-500 to-cyan-500 rounded-lg flex items-center justify-center mb-3 sm:mb-4 mx-auto">
                    <FaCode className="text-2xl sm:text-3xl text-white" />
                  </div>
                  <h3 className="text-lg sm:text-xl font-semibold mb-2">1. Planning</h3>
                  <p className="text-sm sm:text-base text-gray-600">Requirements gathering and project roadmap creation</p>
                </div>
              </div>

              {/* Development Stage */}
              <div
                className="relative"
              >
                <div className="bg-white p-4 sm:p-6 rounded-xl shadow-lg hover:shadow-xl transition-all">
                  <div className="w-12 h-12 sm:w-16 sm:h-16 bg-gradient-to-br from-purple-500 to-pink-500 rounded-lg flex items-center justify-center mb-3 sm:mb-4 mx-auto">
                    <FaCode className="text-2xl sm:text-3xl text-white" />
                  </div>
                  <h3 className="text-lg sm:text-xl font-semibold mb-2">2. Development</h3>
                  <p className="text-sm sm:text-base text-gray-600">Agile development with continuous integration</p>
                </div>
              </div>

              {/* Testing Stage */}
              <div
                className="relative"
              >
                <div className="bg-white p-4 sm:p-6 rounded-xl shadow-lg hover:shadow-xl transition-all">
                  <div className="w-12 h-12 sm:w-16 sm:h-16 bg-gradient-to-br from-green-500 to-teal-500 rounded-lg flex items-center justify-center mb-3 sm:mb-4 mx-auto">
                    <FaCogs className="text-2xl sm:text-3xl text-white" />
                  </div>
                  <h3 className="text-lg sm:text-xl font-semibold mb-2">3. Testing</h3>
                  <p className="text-sm sm:text-base text-gray-600">Rigorous testing and quality assurance</p>
                </div>
              </div>
            </div>

            {/* Second Row */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6 md:gap-8 mt-6 sm:mt-8 md:px-16 lg:px-24">
              {/* Deployment Stage */}
              <div
                className="relative"
              >
                <div className="bg-white p-4 sm:p-6 rounded-xl shadow-lg hover:shadow-xl transition-all">
                  <div className="w-12 h-12 sm:w-16 sm:h-16 bg-gradient-to-br from-orange-500 to-red-500 rounded-lg flex items-center justify-center mb-3 sm:mb-4 mx-auto">
                    <FaCloud className="text-2xl sm:text-3xl text-white" />
                  </div>
                  <h3 className="text-lg sm:text-xl font-semibold mb-2">4. Deployment</h3>
                  <p className="text-sm sm:text-base text-gray-600">Smooth deployment and system integration</p>
                </div>
              </div>

              {/* Maintenance Stage */}
              <div
                className="relative"
              >
                <div className="bg-white p-4 sm:p-6 rounded-xl shadow-lg hover:shadow-xl transition-all">
                  <div className="w-12 h-12 sm:w-16 sm:h-16 bg-gradient-to-br from-indigo-500 to-purple-500 rounded-lg flex items-center justify-center mb-3 sm:mb-4 mx-auto">
                    <FaServer className="text-2xl sm:text-3xl text-white" />
                  </div>
                  <h3 className="text-lg sm:text-xl font-semibold mb-2">5. Maintenance</h3>
                  <p className="text-sm sm:text-base text-gray-600">Ongoing support and continuous improvements</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Quick Contact Section */}
      <div className="pt-0 pb-8 sm:pb-12 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="max-w-6xl mx-auto bg-white rounded-xl sm:rounded-2xl shadow-lg overflow-hidden">
            {/* Header */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="flex flex-col sm:flex-row items-start sm:items-center justify-between p-4 sm:p-6 md:p-8 border-b border-gray-100"
            >
              <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-primary mb-3 sm:mb-0">
                Let's Discuss Your Project
              </h2>
              <div className="flex flex-wrap gap-3 sm:gap-6">
                <div className="flex items-center gap-1.5 sm:gap-2 text-gray-600 text-xs sm:text-sm">
                  <FaBolt className="text-accent" />
                  <span>Quick Response</span>
                </div>
                <div className="flex items-center gap-1.5 sm:gap-2 text-gray-600 text-xs sm:text-sm">
                  <FaChartLine className="text-accent" />
                  <span>Free Consultation</span>
                </div>
              </div>
            </motion.div>

            {/* Contact Form */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="p-4 sm:p-6 md:p-8"
            >
              <form 
                onSubmit={handleSubmit} 
                className="flex flex-col md:flex-row gap-3 sm:gap-4 items-stretch"
              >
                <div className="w-full md:w-1/4 flex flex-col gap-3 sm:gap-4">
                  <div>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Your Name"
                      className={`w-full h-10 sm:h-[46px] px-3 sm:px-4 py-2 sm:py-3 rounded-lg bg-gray-50 border ${formErrors.name ? 'border-red-500' : 'border-gray-200'} text-gray-800 placeholder-gray-500 focus:outline-none focus:border-accent focus:ring-1 focus:ring-accent/20 transition-colors text-sm`}
                      disabled={formStatus === 'submitting'}
                    />
                    {formErrors.name && (
                      <p className="mt-1 text-xs text-red-500">{formErrors.name}</p>
                    )}
                  </div>
                  <div>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Your Email"
                      className={`w-full h-10 sm:h-[46px] px-3 sm:px-4 py-2 sm:py-3 rounded-lg bg-gray-50 border ${formErrors.email ? 'border-red-500' : 'border-gray-200'} text-gray-800 placeholder-gray-500 focus:outline-none focus:border-accent focus:ring-1 focus:ring-accent/20 transition-colors text-sm`}
                      disabled={formStatus === 'submitting'}
                    />
                    {formErrors.email && (
                      <p className="mt-1 text-xs text-red-500">{formErrors.email}</p>
                    )}
                  </div>
                </div>

                <div className="w-full md:w-1/2 flex flex-col gap-3 sm:gap-4">
                  <div>
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      placeholder="Tell us about your project"
                      className={`w-full h-[108px] px-3 sm:px-4 py-2 sm:py-3 rounded-lg bg-gray-50 border ${formErrors.message ? 'border-red-500' : 'border-gray-200'} text-gray-800 placeholder-gray-500 focus:outline-none focus:border-accent focus:ring-1 focus:ring-accent/20 transition-colors text-sm resize-none`}
                      disabled={formStatus === 'submitting'}
                    />
                    {formErrors.message && (
                      <p className="mt-1 text-xs text-red-500">{formErrors.message}</p>
                    )}
                  </div>
                </div>

                <div className="w-full md:w-1/4 flex items-center relative">
                  <button
                    type="submit"
                    disabled={formStatus === 'submitting'}
                    className={`w-full h-10 sm:h-[46px] px-6 rounded-lg bg-accent text-white font-medium hover:bg-accent/90 focus:outline-none focus:ring-2 focus:ring-accent/20 disabled:opacity-50 disabled:cursor-not-allowed transition-colors flex items-center justify-center gap-2 ${
                      formStatus === 'submitting' ? 'cursor-not-allowed' : ''
                    }`}
                  >
                    {formStatus === 'submitting' ? (
                      <>
                        <FaSpinner className="animate-spin" />
                        <span>Sending...</span>
                      </>
                    ) : (
                      <>
                        <span>Send Message</span>
                        <FaPaperPlane className="text-sm" />
                      </>
                    )}
                  </button>
                  <div 
                    ref={toastContainerRef}
                    className="absolute left-0 right-0 -bottom-16"
                  />
                </div>
              </form>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Other sections */}
    </div>
  );
};

export default Home;
