import React from 'react';
import { motion } from 'framer-motion';
import { IconType } from 'react-icons';
import {
  FaLightbulb,
  FaRocket,
  FaHandshake,
  FaReact,
  FaNodeJs,
  FaPencilRuler,
  FaVial,
  FaSync
} from 'react-icons/fa';

// Reusable components from Home page
const ParticleBackground: React.FC = () => {
  const neonColors = [
    'rgba(80, 200, 255, 0.3)',  // Neon blue
    'rgba(255, 80, 255, 0.3)',  // Neon pink
    'rgba(80, 255, 140, 0.3)',  // Neon green
    'rgba(255, 180, 80, 0.3)',  // Neon orange
  ];

  return (
    <div className="absolute inset-0 overflow-hidden">
      {[...Array(50)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute w-1 h-1 rounded-full"
          style={{
            left: Math.random() * 100 + '%',
            top: Math.random() * 100 + '%',
            background: neonColors[Math.floor(Math.random() * neonColors.length)],
            boxShadow: '0 0 10px currentColor',
          }}
          animate={{
            scale: [1, 1.5, 1],
            opacity: [0.3, 0.6, 0.3],
            x: [0, Math.random() * 100 - 50],
            y: [0, Math.random() * 100 - 50],
          }}
          transition={{
            duration: Math.random() * 2 + 2,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        />
      ))}
    </div>
  );
};

interface FloatingIconProps {
  icon: IconType;
  delay?: number;
  index?: number;
  total?: number;
}

const FloatingIcon: React.FC<FloatingIconProps> = ({ icon: Icon, delay = 0, index = 0, total = 1 }) => {
  const neonColors = [
    'text-[#50c8ff]',  // Neon blue
    'text-[#ff50ff]',  // Neon pink
    'text-[#50ff8c]',  // Neon green
    'text-[#ffb450]',  // Neon orange
  ];

  const randomNeonClass = neonColors[Math.floor(Math.random() * neonColors.length)];
  
  const phi = (1 + Math.sqrt(5)) / 2;
  const angle = index * phi * 2 * Math.PI;
  const radius = 40;
  
  const initialX = 50 + radius * Math.cos(angle);
  const initialY = 50 + radius * Math.sin(angle);

  return (
    <motion.div
      className="absolute"
      style={{
        left: `${initialX}%`,
        top: `${initialY}%`
      }}
      initial={{ opacity: 0, scale: 0 }}
      animate={{ 
        opacity: [0.4, 0.8, 0.4],
        scale: [1, 1.2, 1],
        x: [
          0,
          Math.random() * 300 - 150,
          Math.random() * 300 - 150,
          Math.random() * 300 - 150,
          0
        ],
        y: [
          0,
          Math.random() * 200 - 100,
          Math.random() * 200 - 100,
          Math.random() * 200 - 100,
          0
        ]
      }}
      transition={{
        duration: 25,
        delay: delay,
        repeat: Infinity,
        repeatType: "reverse",
        ease: "linear"
      }}
    >
      <div className="relative">
        <Icon className={`${randomNeonClass} text-xl filter drop-shadow-[0_0_8px_currentColor]`} />
        <div 
          className="absolute inset-0 blur-sm"
          style={{ 
            background: 'currentColor',
            opacity: 0.2,
          }} 
        />
      </div>
    </motion.div>
  );
};

const ConnectingLines: React.FC = () => {
  return (
    <svg className="absolute inset-0 w-full h-full">
      {[...Array(5)].map((_, i) => (
        <motion.path
          key={i}
          d={`M${Math.random() * 100},${Math.random() * 100} Q${Math.random() * 100},${Math.random() * 100} ${Math.random() * 100},${Math.random() * 100}`}
          stroke="rgba(80, 200, 255, 0.2)"
          strokeWidth="2"
          fill="none"
          filter="url(#neon-glow)"
          initial={{ pathLength: 0, opacity: 0 }}
          animate={{ 
            pathLength: [0, 1, 0],
            opacity: [0, 0.6, 0]
          }}
          transition={{ 
            duration: 3,
            delay: i * 0.5,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />
      ))}
      <defs>
        <filter id="neon-glow">
          <feGaussianBlur stdDeviation="2" result="blur" />
          <feComposite in="SourceGraphic" in2="blur" operator="over" />
        </filter>
      </defs>
    </svg>
  );
};

// SDLC Phase Component
interface SDLCPhaseProps {
  icon: IconType;
  title: string;
  index: number;
  total: number;
}

const SDLCPhase: React.FC<SDLCPhaseProps> = ({ icon: Icon, title, index, total }) => {
  const radius = 150; // Increased radius for better spacing
  const angle = (index / total) * Math.PI * 2 - Math.PI / 2;
  const x = Math.cos(angle) * radius;
  const y = Math.sin(angle) * radius;

  return (
    <motion.div
      className="absolute"
      style={{
        left: `calc(50% + ${x}px)`,
        top: `calc(50% + ${y}px)`,
      }}
      initial={{ scale: 0, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{
        duration: 0.5,
        delay: index * 0.1,
      }}
    >
      <motion.div
        className="w-24 h-24 -ml-12 -mt-12 flex flex-col items-center justify-center bg-white/10 backdrop-blur-sm rounded-lg"
        whileHover={{ scale: 1.1, backgroundColor: 'rgba(255, 255, 255, 0.15)' }}
      >
        <Icon className="text-2xl text-blue-300 mb-2" />
        <div className="text-white text-xs font-medium text-center px-2">{title}</div>
      </motion.div>
    </motion.div>
  );
};

const About = () => {
  const values = [
    {
      icon: <FaLightbulb />,
      title: "Innovation First",
      description: "We embrace cutting-edge technologies and creative solutions to solve complex challenges.",
      color: "from-blue-400 to-purple-500",
      highlights: ["Creative Problem Solving", "Emerging Technologies", "Forward Thinking"]
    },
    {
      icon: <FaHandshake />,
      title: "Client Partnership",
      description: "We build lasting relationships through transparency, trust, and collaborative success.",
      color: "from-green-400 to-teal-500",
      highlights: ["Transparent Communication", "Long-term Relationships", "Mutual Growth"]
    },
    {
      icon: <FaRocket />,
      title: "Excellence Driven",
      description: "We strive for excellence in every line of code, every design, and every interaction.",
      color: "from-orange-400 to-pink-500",
      highlights: ["Quality Code", "Best Practices", "Continuous Improvement"]
    }
  ];

  const techIcons = [FaReact, FaNodeJs];

  const sdlcPhases = [
    {
      icon: <FaLightbulb />,
      title: "Discovery & Planning",
      description: "Understanding requirements and crafting the perfect strategy",
      color: "from-blue-400 to-cyan-400"
    },
    {
      icon: <FaPencilRuler />,
      title: "Design & Architecture",
      description: "Creating scalable and elegant solutions",
      color: "from-cyan-400 to-teal-400"
    },
    {
      icon: <FaReact />,
      title: "Development",
      description: "Building with cutting-edge technologies",
      color: "from-teal-400 to-green-400"
    },
    {
      icon: <FaVial />,
      title: "Testing & QA",
      description: "Ensuring quality and reliability",
      color: "from-green-400 to-yellow-400"
    },
    {
      icon: <FaRocket />,
      title: "Deployment",
      description: "Seamless delivery to production",
      color: "from-yellow-400 to-orange-400"
    },
    {
      icon: <FaSync />,
      title: "Maintenance & Support",
      description: "Continuous improvement and updates",
      color: "from-orange-400 to-red-400"
    }
  ];

  return (
    <div className="min-h-screen bg-light relative w-full">
      {/* Hero Section */}
      <div className="relative h-[42vh] bg-primary overflow-hidden">
        {/* Animated Background */}
        <div className="absolute inset-0">
          {/* Grid Pattern */}
          <div className="absolute inset-0" style={{ 
            backgroundImage: 'linear-gradient(to right, rgba(80, 200, 255, 0.15) 1px, transparent 1px), linear-gradient(to bottom, rgba(80, 200, 255, 0.15) 1px, transparent 1px)',
            backgroundSize: '30px 30px'
          }} />

          {/* Particles */}
          <ParticleBackground />

          {/* Floating Icons */}
          {techIcons.map((icon, i) => (
            <FloatingIcon 
              key={i} 
              icon={icon} 
              delay={i * 0.2} 
              index={i} 
              total={techIcons.length}
            />
          ))}

          {/* Connecting Lines */}
          <ConnectingLines />
        </div>

        {/* Hero Content */}
        <div className="relative z-10 h-full flex flex-col justify-center items-center px-6 sm:px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-6xl mx-auto text-center"
          >
            <motion.h1 
              className="text-2xl sm:text-3xl md:text-5xl font-bold text-white mb-3 sm:mb-4 leading-tight"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              Empowering Innovation
              <br className="sm:block hidden" />
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-accent to-tech-green">
                Through Technology
              </span>
            </motion.h1>
            
            <motion.p 
              className="text-sm sm:text-base md:text-lg text-gray-300 mb-6 max-w-xl sm:max-w-2xl mx-auto px-2 sm:px-0"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              We are a team of passionate technologists dedicated to transforming ideas
              into innovative digital solutions.
            </motion.p>
          </motion.div>
        </div>

        {/* Curved bottom */}
        <div className="absolute bottom-0 left-0 right-0">
          <svg 
            viewBox="0 0 1440 50" 
            xmlns="http://www.w3.org/2000/svg"
            className="fill-light"
          >
            <path 
              d="M0,32L48,29.3C96,27,192,21,288,21.3C384,21,480,27,576,29.3C672,32,768,32,864,29.3C960,27,1056,21,1152,21.3C1248,21,1344,27,1392,29.3L1440,32L1440,50L1392,50C1344,50,1248,50,1152,50C1056,50,960,50,864,50C768,50,672,50,576,50C480,50,384,50,288,50C192,50,96,50,48,50L0,50Z"
            />
          </svg>
        </div>
      </div>

      {/* Values Section */}
      <div className="py-12 bg-gradient-to-b from-light to-white">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mb-10"
          >
            <h2 className="text-4xl font-bold text-primary mb-3">Our Core Values</h2>
            <p className="text-tech-gray max-w-2xl mx-auto text-lg">
              The principles that drive our innovation and shape our commitment to excellence
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto"
          >
            {values.map((value, index) => (
              <motion.div
                key={index}
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ 
                  delay: index * 0.2,
                  duration: 0.5,
                  ease: "easeOut"
                }}
                whileHover={{ y: -5, transition: { duration: 0.2 } }}
                className="group relative bg-white rounded-xl shadow-lg overflow-hidden"
              >
                {/* Gradient Top Border */}
                <div className={`h-2 bg-gradient-to-r ${value.color}`} />
                
                <div className="p-8">
                  {/* Icon with gradient background */}
                  <div className="relative mb-6">
                    <div className={`absolute inset-0 bg-gradient-to-r ${value.color} opacity-10 rounded-full transform group-hover:scale-110 transition-transform duration-300`} />
                    <div className="relative text-4xl text-primary group-hover:scale-110 transform transition-transform duration-300">
                      {value.icon}
                    </div>
                  </div>

                  {/* Content */}
                  <h3 className={`text-2xl font-bold mb-4 transition-all duration-300 bg-clip-text group-hover:bg-gradient-to-r ${value.color}`}>
                    {value.title}
                  </h3>
                  <p className="text-tech-gray mb-6 text-base leading-relaxed">
                    {value.description}
                  </p>

                  {/* Highlights */}
                  <ul className="space-y-2">
                    {value.highlights.map((highlight, i) => (
                      <motion.li
                        key={i}
                        initial={{ opacity: 0, x: -10 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: (index * 0.2) + (i * 0.1) }}
                        className="flex items-center text-sm text-tech-gray"
                      >
                        <motion.div
                          className={`w-1.5 h-1.5 rounded-full bg-gradient-to-r ${value.color} mr-2`}
                          whileHover={{ scale: 1.5 }}
                        />
                        {highlight}
                      </motion.li>
                    ))}
                  </ul>
                </div>

                {/* Hover gradient overlay */}
                <div className={`absolute inset-0 bg-gradient-to-r ${value.color} opacity-0 group-hover:opacity-5 transition-opacity duration-300`} />
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>

      {/* Our Approach Section */}
      <div className="py-12 sm:py-16 bg-gradient-to-b from-white to-light">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mb-10 sm:mb-16"
          >
            <h2 className="text-3xl sm:text-4xl font-bold text-primary mb-2 sm:mb-3">Our Approach</h2>
            <p className="text-tech-gray max-w-2xl mx-auto text-base sm:text-lg px-4">
              A systematic and agile methodology to deliver exceptional results
            </p>
          </motion.div>

          {/* SDLC Infographic */}
          <div className="relative max-w-7xl mx-auto">
            {/* Connecting Lines - Hide on mobile */}
            <svg className="absolute inset-0 w-full h-full hidden sm:block" style={{ zIndex: 0 }}>
              <motion.path
                d="M100 50 L900 50"
                stroke="url(#lineGradient)"
                strokeWidth="2"
                fill="none"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ duration: 1.5, delay: 0.5 }}
              />
              <defs>
                <linearGradient id="lineGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="0%" stopColor="#60A5FA" />
                  <stop offset="20%" stopColor="#2DD4BF" />
                  <stop offset="40%" stopColor="#4ADE80" />
                  <stop offset="60%" stopColor="#FACC15" />
                  <stop offset="80%" stopColor="#FB923C" />
                  <stop offset="100%" stopColor="#F87171" />
                </linearGradient>
              </defs>
            </svg>

            {/* SDLC Phases */}
            <div className="relative grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-6 sm:gap-4">
              {sdlcPhases.map((phase, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2 }}
                  className="relative"
                >
                  {/* Phase Circle */}
                  <motion.div
                    className={`relative w-12 h-12 sm:w-16 sm:h-16 mx-auto mb-3 sm:mb-4 rounded-full bg-gradient-to-r ${phase.color} shadow-lg
                      flex items-center justify-center text-white text-xl sm:text-2xl
                      transform hover:scale-110 transition-transform duration-300`}
                    whileHover={{ scale: 1.1, rotate: 360 }}
                    transition={{ duration: 0.5 }}
                  >
                    {phase.icon}
                  </motion.div>

                  {/* Phase Content */}
                  <motion.div
                    className="text-center px-2 sm:px-0"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: index * 0.2 + 0.3 }}
                  >
                    <h3 className="text-base sm:text-lg font-semibold text-primary mb-1 sm:mb-2">
                      {phase.title}
                    </h3>
                    <p className="text-xs sm:text-sm text-tech-gray">
                      {phase.description}
                    </p>
                  </motion.div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
