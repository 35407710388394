import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useContactForm } from '../hooks/useContactForm';
import { toast } from 'react-hot-toast';
import {
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaClock,
  FaLinkedin,
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaSpinner,
  FaPaperPlane
} from 'react-icons/fa';

const Contact: React.FC = () => {
  const {
    formData,
    formErrors,
    formStatus,
    handleChange,
    handleSubmit
  } = useContactForm();
  const toastContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (formStatus === 'success') {
      toast.success('Thank you! We will get back to you soon.');
    }
  }, [formStatus]);

  const contactInfo = [
    {
      icon: <FaMapMarkerAlt className="text-tech-blue text-xl" />,
      title: 'Our Location',
      details: ['Goregaon(W), Mumbai - 400104', 'MH, India']
    },
    {
      icon: <FaPhone className="text-tech-blue text-xl" />,
      title: 'Phone Number',
      details: ['+91 9029590167']
    },
    {
      icon: <FaEnvelope />,
      title: 'Email',
      details: ['info@intelisenze.in']
    },
    {
      icon: <FaClock className="text-tech-blue text-xl" />,
      title: 'Working Hours',
      details: ['Mon - Fri: 9:00 AM - 6:00 PM', 'Sat - Sun: Closed']
    }
  ];

  const socialLinks = [
    {
      icon: <FaLinkedin className="text-2xl" />,
      url: '#',
      name: 'LinkedIn'
    },
    {
      icon: <FaFacebook className="text-2xl" />,
      url: '#',
      name: 'Facebook'
    },
    {
      icon: <FaTwitter className="text-2xl" />,
      url: '#',
      name: 'Twitter'
    },
    {
      icon: <FaInstagram className="text-2xl" />,
      url: '#',
      name: 'Instagram'
    }
  ];

  return (
    <div className="bg-light relative w-full">
      {/* Background Pattern */}
      <div className="fixed inset-0 overflow-hidden pointer-events-none w-full">
        {/* Grid Pattern */}
        <div 
          className="absolute inset-0 opacity-[0.03] w-full" 
          style={{ 
            backgroundImage: `
              linear-gradient(to right, #4a90e2 1px, transparent 1px),
              linear-gradient(to bottom, #4a90e2 1px, transparent 1px)
            `,
            backgroundSize: '40px 40px',
            width: '100vw',
            minHeight: '100vh'
          }} 
        />
        
        {/* Gradient Overlay */}
        <div 
          className="absolute inset-0 w-full" 
          style={{ 
            background: `
              radial-gradient(circle at 20% 20%, rgba(74, 144, 226, 0.03) 0%, transparent 50%),
              radial-gradient(circle at 80% 80%, rgba(74, 144, 226, 0.03) 0%, transparent 50%)
            `,
            width: '100vw',
            minHeight: '100vh'
          }}
        />

        {/* Tech Shapes */}
        <svg 
          className="absolute top-0 left-0 opacity-[0.02]" 
          xmlns="http://www.w3.org/2000/svg"
          style={{
            width: '100vw',
            minHeight: '100vh'
          }}
        >
          <defs>
            <pattern id="tech-pattern" x="0" y="0" width="100" height="100" patternUnits="userSpaceOnUse">
              <path d="M10 10h20v20H10z" fill="#4a90e2"/>
              <circle cx="70" cy="70" r="10" fill="#4a90e2"/>
              <path d="M40 40l20 20-20 20z" fill="#4a90e2"/>
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill="url(#tech-pattern)"/>
        </svg>
      </div>

      {/* Main Content */}
      <div className="relative z-10">
        {/* Hero Section */}
        <div className="container mx-auto px-4 py-8">
          {/* Section Title */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center mb-6 lg:mb-8"
          >
            <h1 className="text-4xl md:text-5xl font-bold text-primary mb-2">
              Get in Touch
            </h1>
            <p className="text-tech-gray text-lg max-w-2xl mx-auto">
              Have a question or want to work together? We'd love to hear from you.
            </p>
          </motion.div>

          {/* Contact Grid */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            {/* Contact Information */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="lg:col-span-1 space-y-4 order-2 lg:order-1"
            >
              {contactInfo.map((info, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-white p-4 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
                >
                  <div className="flex items-center gap-3 mb-2">
                    {info.icon}
                    <h3 className="text-lg font-semibold text-primary">
                      {info.title}
                    </h3>
                  </div>
                  <div className="pl-7">
                    {info.details.map((detail, i) => (
                      <p key={i} className="text-tech-gray text-sm">
                        {detail}
                      </p>
                    ))}
                  </div>
                </motion.div>
              ))}

              {/* Social Media Card */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
                className="bg-white p-4 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
              >
                <div className="flex items-center gap-3 mb-3">
                  <FaInstagram className="text-tech-blue text-xl" />
                  <h3 className="text-lg font-semibold text-primary">
                    Connect With Us
                  </h3>
                </div>
                <div className="grid grid-cols-2 gap-3 pl-7">
                  {socialLinks.map((social, index) => (
                    <a
                      key={index}
                      href={social.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center gap-2 text-tech-gray hover:text-tech-blue transition-colors"
                    >
                      {social.icon}
                      <span className="text-sm">{social.name}</span>
                    </a>
                  ))}
                </div>
              </motion.div>
            </motion.div>

            {/* Contact Form */}
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.4 }}
              className="lg:col-span-2 bg-white p-6 rounded-lg shadow-lg order-1 lg:order-2"
            >
              <h3 className="text-2xl font-semibold text-primary mb-6">
                Send us a Message
              </h3>
              <form 
                onSubmit={handleSubmit} 
                className="space-y-4"
              >
                <div>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Your Name"
                    className={`w-full h-10 sm:h-[46px] px-3 sm:px-4 py-2 sm:py-3 rounded-lg bg-gray-50 border ${formErrors.name ? 'border-red-500' : 'border-gray-200'} text-gray-800 placeholder-gray-500 focus:outline-none focus:border-accent focus:ring-1 focus:ring-accent/20 transition-colors text-sm`}
                    disabled={formStatus === 'submitting'}
                  />
                  {formErrors.name && (
                    <p className="mt-1 text-xs text-red-500">{formErrors.name}</p>
                  )}
                </div>

                <div>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Your Email"
                    className={`w-full h-10 sm:h-[46px] px-3 sm:px-4 py-2 sm:py-3 rounded-lg bg-gray-50 border ${formErrors.email ? 'border-red-500' : 'border-gray-200'} text-gray-800 placeholder-gray-500 focus:outline-none focus:border-accent focus:ring-1 focus:ring-accent/20 transition-colors text-sm`}
                    disabled={formStatus === 'submitting'}
                  />
                  {formErrors.email && (
                    <p className="mt-1 text-xs text-red-500">{formErrors.email}</p>
                  )}
                </div>

                <div>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Your Message"
                    rows={4}
                    className={`w-full h-[108px] px-3 sm:px-4 py-2 sm:py-3 rounded-lg bg-gray-50 border ${formErrors.message ? 'border-red-500' : 'border-gray-200'} text-gray-800 placeholder-gray-500 focus:outline-none focus:border-accent focus:ring-1 focus:ring-accent/20 transition-colors text-sm resize-none`}
                    disabled={formStatus === 'submitting'}
                  />
                  {formErrors.message && (
                    <p className="mt-1 text-xs text-red-500">{formErrors.message}</p>
                  )}
                </div>

                <div className="relative">
                  <button
                    type="submit"
                    disabled={formStatus === 'submitting'}
                    className={`w-full h-10 sm:h-[46px] px-6 rounded-lg bg-accent text-white font-medium hover:bg-accent/90 focus:outline-none focus:ring-2 focus:ring-accent/20 disabled:opacity-50 disabled:cursor-not-allowed transition-colors flex items-center justify-center gap-2 ${
                      formStatus === 'submitting' ? 'cursor-not-allowed' : ''
                    }`}
                  >
                    {formStatus === 'submitting' ? (
                      <>
                        <FaSpinner className="animate-spin" />
                        <span>Sending...</span>
                      </>
                    ) : (
                      <>
                        <span>Send Message</span>
                        <FaPaperPlane className="text-sm" />
                      </>
                    )}
                  </button>
                  {/* Toast container positioned relative to the button */}
                  <div 
                    ref={toastContainerRef}
                    className="absolute left-0 right-0 -bottom-16"
                    style={{ zIndex: 1000 }}
                  />
                </div>
              </form>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
