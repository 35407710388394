import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaExternalLinkAlt } from 'react-icons/fa';

const Projects: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState('all');

  const categories = ['all', 'websites', 'web apps', 'android apps'];

  const projects = [
    // Websites
    {
      title: 'Kalash Elect',
      category: 'websites',
      description: 'Professional website for an electrical services company, featuring service listings, project portfolio, and contact information.',
      image: '/assets/images/projects/kalashelect.jpg',
      technologies: ['React', 'Tailwind CSS', 'JavaScript'],
      live: 'https://kalashelect.com'
    },
    {
      title: 'Kamlesh P Mehta',
      category: 'websites',
      description: 'Personal portfolio website for a professional, showcasing their work, achievements, and contact information.',
      image: '/assets/images/projects/kamleshpmehta.jpg',
      technologies: ['React', 'Tailwind CSS', 'JavaScript'],
      live: 'https://kamleshpmehta.com'
    },
    {
      title: 'K Dental',
      category: 'websites',
      description: 'Modern website for a dental clinic, featuring service information, appointment booking, and patient resources.',
      image: '/assets/images/projects/kdental.jpg',
      technologies: ['React', 'Tailwind CSS', 'JavaScript'],
      live: 'https://kdental.in'
    },
    // Web Apps
    {
      title: 'Sequinze',
      category: 'web apps',
      description: 'Advanced web application with sophisticated features and modern user interface.',
      image: '/assets/images/projects/sequinze.jpg',
      technologies: ['React', 'Node.js', 'TypeScript', 'Tailwind CSS'],
      live: 'https://sequinze.com'
    },
    // Android Apps
    {
      title: 'Interview Prep Guide',
      category: 'android apps',
      description: 'Comprehensive interview preparation app with questions, answers, and study materials.',
      image: '/assets/images/projects/Interview Prep Guide App.jpg',
      technologies: ['Android', 'Java', 'SQLite'],
      playstore: '#'
    },
    {
      title: 'MCA STUD',
      category: 'android apps',
      description: 'Educational app designed specifically for MCA students with study materials and resources.',
      image: '/assets/images/projects/MCA STUD-android-app.jpg',
      technologies: ['Android', 'Java', 'SQLite'],
      playstore: '#'
    },
    {
      title: 'Medical Disease Dictionary',
      category: 'android apps',
      description: 'Comprehensive medical reference app with detailed information about various diseases.',
      image: '/assets/images/projects/Medical Disease Dictionary Android App.jpg',
      technologies: ['Android', 'Java', 'SQLite'],
      playstore: '#'
    }
  ];

  const filteredProjects = selectedCategory === 'all'
    ? projects
    : projects.filter(project => project.category === selectedCategory);

  return (
    <div className="min-h-screen bg-light relative w-full">
      {/* Background Pattern */}
      <div className="fixed inset-0 overflow-hidden pointer-events-none w-full">
        <div 
          className="absolute inset-0 opacity-[0.03] w-full" 
          style={{ 
            backgroundImage: `
              linear-gradient(to right, #4a90e2 1px, transparent 1px),
              linear-gradient(to bottom, #4a90e2 1px, transparent 1px)
            `,
            backgroundSize: '40px 40px',
            width: '100vw',
            minHeight: '100vh'
          }} 
        />
        
        <div 
          className="absolute inset-0 w-full" 
          style={{ 
            background: `
              radial-gradient(circle at 20% 20%, rgba(74, 144, 226, 0.03) 0%, transparent 50%),
              radial-gradient(circle at 80% 80%, rgba(74, 144, 226, 0.03) 0%, transparent 50%)
            `,
            width: '100vw',
            minHeight: '100vh'
          }}
        />
      </div>

      <div className="relative z-10 container mx-auto px-4 py-16 sm:py-24">
        {/* Section Title */}
        <div className="text-center mb-12">
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-4">
            Our Projects
          </h2>
          <p className="text-lg text-gray-600">
            Explore our diverse portfolio of digital solutions
          </p>
        </div>

        {/* Category Filter */}
        <div className="flex flex-wrap justify-center gap-2 mb-12">
          {categories.map(category => (
            <button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`px-4 py-2 rounded-full text-sm font-medium transition-all
                ${selectedCategory === category
                  ? 'bg-primary text-white shadow-lg'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
            >
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </button>
          ))}
        </div>

        {/* Projects Grid */}
        <motion.div
          key={selectedCategory}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8"
        >
          {filteredProjects.map((project, index) => (
            <motion.div
              key={project.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
              className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow"
            >
              {/* Project Image */}
              <div className="relative aspect-[4/3] overflow-hidden bg-gray-100">
                <img
                  src={project.image}
                  alt={project.title}
                  className="w-full h-full object-cover object-center"
                />
              </div>

              {/* Project Info */}
              <div className="p-6">
                <h3 className="text-xl font-bold text-gray-900 mb-2">
                  {project.title}
                </h3>
                <p className="text-gray-600 mb-4 line-clamp-2">
                  {project.description}
                </p>

                {/* Technologies */}
                <div className="flex flex-wrap gap-2 mb-4">
                  {project.technologies.map(tech => (
                    <span
                      key={tech}
                      className="px-2 py-1 bg-blue-50 text-blue-600 text-xs font-medium rounded"
                    >
                      {tech}
                    </span>
                  ))}
                </div>

                {/* Links */}
                <div className="flex justify-end gap-3">
                  {project.live && (
                    <a
                      href={project.live}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary hover:text-primary-dark transition-colors"
                      title="View Live"
                    >
                      <FaExternalLinkAlt className="w-5 h-5" />
                    </a>
                  )}
                  {project.playstore && (
                    <a
                      href={project.playstore}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary hover:text-primary-dark transition-colors"
                      title="View on Play Store"
                    >
                      <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M3.609 1.814L13.792 12 3.609 22.186a.996.996 0 0 1-.609-.92V2.734a1 1 0 0 1 .609-.92zm10.89 10.893l2.302 2.302-10.937 6.333 8.635-8.635zm3.535-3.535l-12.472 7.215 8.635-8.635 2.302 2.302a.999.999 0 0 1 1.535-.882zM3.827 1.019l10.937 6.333-2.302 2.302-8.635-8.635z"/>
                      </svg>
                    </a>
                  )}
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default Projects;
