import React from 'react';
import { motion } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import {
  FaLinkedin,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
  FaHome,
  FaInfoCircle,
  FaCog,
  FaProjectDiagram,
  FaEnvelopeOpen,
  FaLaptopCode,
  FaCode,
  FaDesktop,
  FaMobile
} from 'react-icons/fa';

const Footer: React.FC = () => {
  const navigate = useNavigate();
  
  const quickLinks = [
    { name: 'Home', icon: FaHome, path: '/' },
    { name: 'About Us', icon: FaInfoCircle, path: '/about' },
    { name: 'Services', icon: FaCog, path: '/services' },
    { name: 'Projects', icon: FaProjectDiagram, path: '/projects' },
    { name: 'Contact', icon: FaEnvelopeOpen, path: '/contact' }
  ];

  const coreServices = [
    { name: 'Website Development', icon: FaLaptopCode, section: 'web-development' },
    { name: 'Software Development', icon: FaCode, section: 'software-development' },
    { name: 'Web App Development', icon: FaDesktop, section: 'web-app-development' },
    { name: 'Mobile Development', icon: FaMobile, section: 'mobile-development' }
  ];

  const handleServiceClick = (section: string) => {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleQuickLinkClick = (path: string, e: React.MouseEvent) => {
    e.preventDefault();
    navigate(path);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className="relative bg-gray-900 text-gray-300">
      {/* Background Pattern */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        {/* Grid Pattern */}
        <div 
          className="absolute inset-0 opacity-[0.03]" 
          style={{ 
            backgroundImage: `
              linear-gradient(to right, #4a90e2 1px, transparent 1px),
              linear-gradient(to bottom, #4a90e2 1px, transparent 1px)
            `,
            backgroundSize: '40px 40px'
          }} 
        />
        
        {/* Gradient Overlay */}
        <div 
          className="absolute inset-0" 
          style={{ 
            background: `
              radial-gradient(circle at 20% 20%, rgba(74, 144, 226, 0.03) 0%, transparent 50%),
              radial-gradient(circle at 80% 80%, rgba(74, 144, 226, 0.03) 0%, transparent 50%)
            `
          }}
        />

        {/* Tech Shapes */}
        <svg 
          className="absolute top-0 left-0 opacity-[0.02]" 
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
        >
          <defs>
            <pattern id="tech-pattern" x="0" y="0" width="100" height="100" patternUnits="userSpaceOnUse">
              <path d="M10 10h20v20H10z" fill="#4a90e2"/>
              <circle cx="70" cy="70" r="10" fill="#4a90e2"/>
              <path d="M40 40l20 20-20 20z" fill="#4a90e2"/>
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill="url(#tech-pattern)"/>
        </svg>
      </div>

      {/* Content */}
      <div className="relative z-10 container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Company Info */}
          <div className="space-y-6">
            <div className="space-y-4">
              <h3 className="text-2xl font-bold text-white mb-4">Intelisenze Infotech</h3>
              <p className="text-gray-400 leading-relaxed">
                Transforming ideas into innovative digital solutions through cutting-edge technology.
              </p>
            </div>

            <div>
              <div className="flex space-x-4">
                <motion.a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  whileHover={{ scale: 1.1 }}
                  className="bg-gray-800 p-2 rounded-full hover:bg-gray-700 transition-colors"
                >
                  <FaLinkedin className="text-xl" />
                </motion.a>
                <motion.a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  whileHover={{ scale: 1.1 }}
                  className="bg-gray-800 p-2 rounded-full hover:bg-gray-700 transition-colors"
                >
                  <FaFacebook className="text-xl" />
                </motion.a>
                <motion.a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  whileHover={{ scale: 1.1 }}
                  className="bg-gray-800 p-2 rounded-full hover:bg-gray-700 transition-colors"
                >
                  <FaTwitter className="text-xl" />
                </motion.a>
                <motion.a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  whileHover={{ scale: 1.1 }}
                  className="bg-gray-800 p-2 rounded-full hover:bg-gray-700 transition-colors"
                >
                  <FaInstagram className="text-xl" />
                </motion.a>
              </div>
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h4 className="text-lg font-semibold text-white mb-4">Quick Links</h4>
            <ul className="space-y-2">
              {quickLinks.map((item, index) => (
                <motion.li
                  key={index}
                  whileHover={{ x: 5 }}
                  className="transition-colors duration-300"
                >
                  <a
                    href={item.path}
                    onClick={(e) => handleQuickLinkClick(item.path, e)}
                    className="text-gray-400 hover:text-white transition-colors duration-300 flex items-center space-x-3"
                  >
                    <div className="flex items-center space-x-2">
                      <item.icon className="text-blue-400" />
                      <span>{item.name}</span>
                    </div>
                  </a>
                </motion.li>
              ))}
            </ul>
          </div>

          {/* Core Services */}
          <div>
            <h4 className="text-lg font-semibold text-white mb-4">Our Services</h4>
            <ul className="space-y-2">
              {coreServices.map((service, index) => (
                <motion.li
                  key={index}
                  whileHover={{ x: 5 }}
                  className="transition-colors duration-300"
                >
                  <Link
                    to={`/services#${service.section}`}
                    className="text-gray-400 hover:text-white transition-colors duration-300 flex items-center space-x-3"
                    onClick={() => handleServiceClick(service.section)}
                  >
                    <div className="flex items-center space-x-2">
                      <service.icon className="text-blue-400" />
                      <span>{service.name}</span>
                    </div>
                  </Link>
                </motion.li>
              ))}
            </ul>
          </div>

          {/* Contact Details */}
          <div>
            <h4 className="text-lg font-semibold text-white mb-4">Contact Details</h4>
            <div className="space-y-4">
              <div className="flex items-start space-x-3">
                <FaMapMarkerAlt className="text-blue-500 mt-1" />
                <span className="text-gray-400">
                  Goregaon(W), Mumbai - 400104<br />
                  MH, India
                </span>
              </div>
              <div className="flex items-center space-x-3">
                <FaPhone className="text-blue-500" />
                <span className="text-gray-400">+91 9029590167</span>
              </div>
              <div className="flex items-center space-x-3">
                <FaEnvelope className="text-blue-500" />
                <span className="text-gray-400">info@intelisenze.in</span>
              </div>
            </div>
          </div>
        </div>

        {/* Copyright and Legal */}
        <div className="border-t border-gray-800 mt-12 pt-8">
          <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
            <p className="text-gray-400">
              &copy; {new Date().getFullYear()} Intelisenze Infotech. All rights reserved.
            </p>
            <div className="flex space-x-6">
              <Link 
                to="/privacy-policy" 
                className="text-gray-400 hover:text-blue-400 hover:brightness-125 transition-all text-sm"
              >
                Privacy Policy
              </Link>
              <span className="text-gray-400">•</span>
              <Link 
                to="/terms-of-service" 
                className="text-gray-400 hover:text-blue-400 hover:brightness-125 transition-all text-sm"
              >
                Terms of Service
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
