interface EmailData {
  name: string;
  email: string;
  message: string;
  recaptchaToken: string;
}

export const sendContactForm = async (data: EmailData): Promise<void> => {
  // Local development mode
  if (window.location.hostname === 'localhost') {
    // Simulate API delay
    await new Promise(resolve => setTimeout(resolve, 1000));
    return;
  }

  // Production mode
  try {
    const response = await fetch('https://api.intelisenze.in/send-email.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    });

    const result = await response.json();
    
    if (!response.ok) {
      console.error('API Error:', result);
      if (response.status === 429) {
        throw new Error('Too many requests. Please try again later.');
      }
      throw new Error(result.details?.[0] || result.error || 'Failed to send email');
    }

    if (result.status !== 'success') {
      console.error('API Response:', result);
      throw new Error(result.message || 'Failed to send email');
    }
  } catch (error) {
    console.error('Send Email Error:', error);
    throw error;
  }
};
